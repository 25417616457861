<template>
  <div class="approved-posts">
    <PostList
      :posts="posts"
      :stories="stories"
      :currentCustomer="getCurrentCustomer"
      :isShowCloseIcon="false"
      :postListType="'narrow short'"
      :postType="'approved'"
      isShowComment
      isSmall
      :isShowEditIcon="!getCurrentCustomer.meta_tester"
      @approveAction="approvedHandler"
      @postDeletedSuccessfully="getPosts"
      @postModified="getPosts"
      @updateAmountPost="deletePostHandler"
      isShowTopActions
      :isShowDeletedIcon="!getCurrentCustomer.meta_tester"
      isShowDateCreation
      isCanEditPublishDate
      isShowPublishTime
      headerTitle="Approved"
      isShowPreview
      :checkedList="getApprovedList"
      isCanSwap
      @updateSchedule="updateScheduleHandler"
      @updateRush="updateRushPost($event)"
      isSortedByScheduledForStr
      isCanSelectAllPosts
      isShowStoryTitle
      isCanSelectAllStories
      @selectOrUnselectAllPosts="selectOrUnselectAllPostsHandler"
      @selectOrUnselectAllStories="selectOrUnselectAllStoriesHandler"
      isCanShowEditedLabel
      isCanShowBackupData
      isCanShowFailedLabel
      isShowDeleteSchedulingIcon
      isShowActionDate
      actionDateTitle="Approved at:"
    />
    <ModalDialog
      :isShow="dialog.isShow"
      :title="dialog.title"
      :icon="require('@/assets/img/icons/success-icon.svg')"
      :footerButtons="dialog.footerButtons"
      @success="updatePost()"
      @close="dialog.isShow = false"
    >
      <template v-slot:description>
        Post(s) is exported successfully to SMM.
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="exportFailedDialog.isShow"
      :title="exportFailedDialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="exportFailedDialog.footerButtons"
      @close="exportFailedDialog.isShow = false"
    >
    </ModalDialog>
    <ModalDialog
      :isShow="showSchedulingPopUp.isShow"
      :title="showSchedulingPopUp.title"
      :footerButtons="showSchedulingPopUp.footerButtons"
      @success="successExportHandler(), (showSchedulingPopUp.isShow = false)"
      @close="(showSchedulingPopUp.isShow = false), $emit('isExport')"
    />
    <ModalDialog
      :isShow="exportDialog.isShow"
      :title="exportDialog.title"
      :footerButtons="exportDialog.footerButtons"
      @success="
        exportToSmmHandler(exportComment), $emit('isExport');
        exportDialog.isShow = false;
      "
      @cancel="
        exportDialog.isShow = false;
        $emit('isExport');
        exportComment = '';
      "
      @close="
        exportComment = '';
        exportDialog.isShow = false;
        $emit('isExport');
      "
    >
      <template v-slot:description
        >Are you sure you want to Export the content?
        <v-textarea
          class="pt-4"
          v-model="exportComment"
          placeholder="Type your comment"
          outlined
        ></v-textarea>
      </template>
    </ModalDialog>
    <Loader v-if="isLoading" class="loader" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalDialog from "@/components/global/ModalDialog";
import PostList from "@/components/createPost/PostList";
import helper from "@/mixins/helper";
import Loader from "@/components/global/Loader";
import { log } from "logrocket";

export default {
  name: "ApprovedTab",
  props: {
    isExport: {
      type: Boolean,
      default: false,
    },
    isExportRush: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    posts: [],
    stories: [],
    approvedPostIds: [],
    isLoading: false,
    exportComment: "",
    dialog: {
      isShow: false,
      title: "Export Success!",
      footerButtons: [{ name: "Ok", emitName: "success", btnClass: "violet" }],
    },
    showSchedulingPopUp: {
      isShow: false,
      title: "Some export dates are not for the upcoming week! Please check.",
      footerButtons: [
        {
          name: "It's OK, continue. ",
          emitName: "success",
          btnClass: "violet",
        },
        { name: "Cancel export", emitName: "close" },
      ],
    },
    exportDialog: {
      isShow: false,
      title:
        "After exporting all the content will be removed from the dashboard for good",
      footerButtons: [
        { name: "Cancel", emitName: "cancel" },
        { name: "Yes", emitName: "success", btnClass: "violet" },
      ],
    },
    exportFailedDialog: {
      isShow: false,
      title: "Publish failed for one or more items , please schedule manually",
      footerButtons: [{ name: "Close", emitName: "close" }],
    },
  }),
  components: {
    PostList,
    ModalDialog,
    Loader,
  },
  mixins: [helper],
  async created() {
    await this.getPosts();
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("createPost", ["getApprovedList"]),
  },
  watch: {
    isExport(newValue) {
      if (newValue) {
        this.checkIsCanShare(this.exportComment);
      }
    },
    async isExportRush(newValue) {
      if (newValue) {
        await this.publishRush();
      }
    },
    getApprovedList() {
      this.$emit("publishPost", this.getApprovedList);
    },
  },
  methods: {
    ...mapActions("createPost", [
      "fetchPosts",
      "exportPosts",
      "getSuggestedPublishDate",
      "updatePublishDate",
    ]),
    ...mapMutations("post", [
      "setSelectedPostList",
      "refreshSelectedPostList",
      "deleteSelectedPostListById",
    ]),
    ...mapMutations("createPost", [
      "setToApprovedList",
      "setToApprovedListRush",
      "deleteFromApprovedList",
      "refreshStateByName",
    ]),
    updateRushPost(event) {
      this.posts.find((item) => item.id === event.id).rush = false;
    },
    async publishRush() {
      try {
        this.isLoading = true;
        const { data } = await this.exportPosts({
          customerId: this.getCurrentCustomer.id,
          postsIdList: this.getApprovedList,
          smmId: this.getCurrentUser.id,
          exportedTo: "publish",
        });
        if (data === "failed") {
          this.exportFailedDialog.isShow = true;
        }
        await this.refreshStateByName({
          stateName: "approvedList",
          value: [],
        });
        await this.refreshStateByName({
          stateName: "approvedListRush",
          value: [],
        });
        this.$emit("isExportRush");
        await this.getPosts();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        await this.refreshStateByName({
          stateName: "approvedList",
          value: [],
        });
        await this.refreshStateByName({
          stateName: "approvedListRush",
          value: [],
        });
        this.exportFailedDialog.isShow = true;
        this.$emit("isExportRush");
        await this.getPosts();
        console.log(e);
      }
    },
    async getPosts() {
      const data = await this.fetchPosts({
        routeName: "approved",
        customerId: this.getCurrentCustomer.id,
        limit: 100,
      });
      this.posts = [];
      this.stories = [];
      data.forEach((item) => {
        item.type === "post" ? this.posts.push(item) : this.stories.push(item);
      });
    },
    async approvedHandler(data) {
      if (data.approveFlag) {
        this.setToApprovedList(data.postId);
        this.setToApprovedListRush({ id: data.postId, isRush: data.post.rush });
        if (data.post.type === "post") {
          await this.updateSchedule(data, this.posts);
        } else {
          await this.updateSchedule(data, this.stories);
        }
      } else {
        this.deleteFromApprovedList(data.postId);
        this.deleteSelectedPostListById(data.postId);
      }
    },

    setPermanentExportNote() {
      this.exportComment = this.getCurrentCustomer.permanent_export_note
        ? `${this.getCurrentCustomer.permanent_export_note}\n*************\n`
        : "";
    },
    checkIsCanShare() {
      this.setPermanentExportNote();
      if (this.isCanShareToClient()) {
        this.exportDialog.isShow = true;
      } else {
        this.showSchedulingPopUp.isShow = true;
      }
    },
    async exportToSmmHandler(exportComment = undefined) {
      this.isLoading = true;
      try {
        const { data } = await this.exportPosts({
          customerId: this.getCurrentCustomer.id,
          postsIdList: this.getApprovedList,
          smmId: this.getCurrentUser.id,
          exportedTo: "smm",
          smmComment: exportComment || undefined,
        });
        this.download(data);
        await this.refreshStateByName({
          stateName: "approvedList",
          value: [],
        });
        this.dialog.isShow = true;
        this.exportComment = "";
      } catch (e) {
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
    updatePost() {
      this.getPosts();
      this.dialog.isShow = false;
      this.$emit("updateAmountPost");
    },
    deletePostHandler() {
      this.getPosts();
      this.$emit("updateAmountPost");
    },
    async updateSchedule(data, postList = []) {
      this.setSelectedPostList(data.post);
      if (data.post.scheduledForStr.trim() === "") {
        const { publishAtStr } = await this.getSuggestedPublishDate(
          data.post.id
        );
        await this.updatePublishDate({
          id: data.post.id,
          scheduledForStr: publishAtStr,
        });
        let resultPost = postList.find((post) => post.id === data.post.id);
        resultPost ? (resultPost.scheduledForStr = publishAtStr) : null;
      }
    },
    updateScheduleHandler(postData) {
      let searchPost =
        this.posts.find((post) => post.id === postData.postId) ||
        this.stories.find((post) => post.id === postData.postId);
      searchPost
        ? (searchPost.scheduledForStr = postData.scheduledForStr)
        : null;
    },
    selectOrUnselectAllPostsHandler(isNeedToSelectAllPosts) {
      this.posts.forEach((post) => {
        this.deleteFromApprovedList(post.id);
      });

      if (isNeedToSelectAllPosts) {
        this.posts.forEach((post) => this.setToApprovedList(post.id));
      }
    },
    selectOrUnselectAllStoriesHandler(isNeedToSelectAllStories) {
      this.stories.forEach((post) => {
        this.deleteFromApprovedList(post.id);
      });

      if (isNeedToSelectAllStories) {
        this.stories.forEach((post) => this.setToApprovedList(post.id));
      }
    },
    isCanShareToClient() {
      let isCanShare = true;
      this.getApprovedList.forEach((approvedItem) => {
        [...this.posts, ...this.stories].find((i) => {
          if (i.id === approvedItem) {
            if (i.scheduledForStr) {
              this.$dayjs(this.parseStrDate(i.scheduledForStr).dateStr).unix() <
              this.$dayjs()
                .add("1", "weeks")
                .startOf("week")
                .add("1", "day")
                .unix()
                ? (isCanShare = false)
                : null;
            } else {
              isCanShare = false;
            }
          }
        });
      });

      return isCanShare;
    },
    successExportHandler() {
      this.exportDialog.isShow = true;
    },
    exportModal() {
      this.exportComment = "";
      this.exportDialog.isShow = !this.exportDialog.isShow;
    },
  },
  destroyed() {
    this.refreshStateByName({
      stateName: "approvedList",
      value: [],
    });
    this.refreshSelectedPostList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.approved-posts {
  .loader {
    ::v-deep {
      position: fixed;
    }
  }
}
</style>
